<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>{{
      selectedGroup ? $t("edit") : $t("add-group")
    }}</template>
    <template #body
      ><group-form @onClose="closeModal" :group="selectedGroup"
    /></template>
  </Modal>

  <Modal :show="showUserModal" @onClose="closeUserModal">
    <template #header>{{ $t("assign-user") }}</template>
    <template #body><assign-user-form @onClose="closeUserModal" /></template>
  </Modal>

  <!-- show only if loading from server not from cache -->
  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-groups.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("groups") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            v-if="isAdmin"
            @click="openModal"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              text-white text-xs
              uppercase
            "
          >
            + {{ $t("add-group") }}
          </button>
          <button
            v-if="isAdmin"
            @click="openUserModal"
            class="
              border border-WAORANGE-500
              hover:bg-white
              mr-2
              px-4
              py-2
              rounded-lg
              text-xs
              uppercase
            "
          >
            {{ $t("assign-user") }}
          </button>
        </div>
        <GroupFilter />
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <group-table-row></group-table-row>
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredGroups.length > 0">
        <Group
          v-for="group in filteredGroups"
          :key="group.id"
          :group="group"
          @edit="editGroup(group)"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Group from "@/components/groups/Group.vue";
import GroupFilter from "@/components/groups/GroupFilter.vue";
import GroupForm from "@/components/groups/GroupForm.vue";
import GroupTableRow from "@/components/groups/GroupTableRow.vue";
import AssignUserForm from "@/components/groups/AssignUserForm.vue";

import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Group,
    Loader,
    GroupFilter,
    GroupForm,
    Modal,
    AssignUserForm,
    GroupTableRow,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters("groups", ["loading", "error", "list", "filteredGroups"]),
    isAdmin() {
      return (
        (this.currentUser && this.currentUser?.role?.group?.admin) ||
        this.currentUser?.isSuper
      );
    },
  },
  async created() {
    await this.getGroups();
  },
  data() {
    return {
      showModal: false,
      showUserModal: false,
      selectedGroup: null,
    };
  },
  methods: {
    ...mapActions("groups", ["getGroups"]),
    openModal() {
      this.showModal = true;
      this.selectedGroup = null;
    },
    closeModal() {
      this.showModal = false;
      this.selectedGroup = null;
    },
    openUserModal() {
      this.showUserModal = true;
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    editGroup(group) {
      this.selectedGroup = group;
      this.showModal = true;
    },
  },
};
</script>
