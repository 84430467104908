<template>
  <div>
    <!-- For group and sub-group component -->
    <router-link
      :to="isSub ? `/subgroups/${group._id}` : `/groups/${group._id}`"
      v-if="!isSubSub"
      class="block bg-white cursor-pointer hover:bg-WALIGHTBLUE-50 max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-sm shadow-gray-100 lg:px-4"
    >
      <div class="flex items-center justify-between">
        <div class="flex pr-1 flex-row">
          <div class="text-left flex w-44 md:w-80">
            <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
              {{ group?.name }}
            </p>
          </div>
          <div class="w-36 px-1 md:w-80 hidden sm:flex">
            <p class="text-WADARKBLUE-500 text-sm font-thin">
              {{ getGroupName(group?.users) }}
            </p>
          </div>
        </div>

        <div class="flex">
          <div v-if="isAdmin" class="flex relative justify-end w-20">
            <button
              @click.prevent="openStatusModal(group)"
              class="flex font-thin text-sm items-center"
              :class="{
                'text-WAGREEN-500': group.status === 'Active',
                'text-WAORANGE-500': group.status === 'Disabled',
              }"
            >
              {{ group.status }}
              <img
                class="w-2 ml-1"
                src="/chevron-down-dark.svg"
                alt="chevron"
              />
            </button>
            <status-modal
              v-if="showStatus.status && showStatus.id === group._id"
              v-model="status"
              :data="statusData"
              @close="closeStatusModal"
            ></status-modal>
          </div>
          <div v-if="isAdmin" class="relative flex justify-end w-20">
            <!-- edit dropdown -->
            <option-dropdown-new
              @btnClick="openOptionDropDown(group._id)"
              @onClose="closeOptionDropdown"
              :show="
                showOptionDropdown.status && showOptionDropdown.id === group._id
              "
            >
              <button
                @click.prevent="$emit('edit')"
                class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
              >
                {{ $t("edit") }}
              </button>
              <button
                @click.prevent="openConfirmModal(group._id)"
                class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
              >
                {{ $t("delete") }}
                <!-- <img src="/copy.svg" alt="" /> -->
              </button>
            </option-dropdown-new>

            <div
              class="absolute -bottom-16 w-80"
              v-if="showConfirm.status && showConfirm.id === group._id"
            >
              <confirm-modal
                :show="showConfirm.status && showConfirm.id === group._id"
                @no="closeConfirmModal"
                @yes="confirmDelete(group._id)"
              ></confirm-modal>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <button
      v-else
      class="block w-full cursor-default bg-white max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-sm shadow-gray-100 lg:px-4"
    >
      <div class="flex items-center justify-between">
        <div class="flex flex-row">
          <div class="text-left flex w-44 md:w-80">
            <p class="text-WADARKBLUE-500 text-sm font-thin">
              {{ group?.name }}
            </p>
          </div>
          <div class="w-36 md:w-80 hidden sm:flex">
            <p class="text-WADARKBLUE-500 text-sm">
              {{ getGroupName(group?.users) }}
            </p>
          </div>
        </div>
        <div class="flex">
          <div v-if="isAdmin" class="flex relative justify-end w-20">
            <button
              @click.prevent="openStatusModal(group)"
              class="flex font-thin text-sm items-center"
              :class="{
                'text-WAGREEN-500': group.status === 'Active',
                'text-WAORANGE-500': group.status === 'Disabled',
              }"
            >
              {{ group.status }}
              <img
                class="w-2 ml-1"
                src="/chevron-down-dark.svg"
                alt="chevron"
              />
            </button>
            <status-modal
              v-if="showStatus.status && showStatus.id === group._id"
              v-model="status"
              :data="statusData"
              @close="closeStatusModal"
            ></status-modal>
          </div>
          <div v-if="isAdmin" class="relative flex justify-end w-20">
            <!-- edit dropdown -->
            <option-dropdown-new
              @btnClick="openOptionDropDown(group._id)"
              @onClose="closeOptionDropdown"
              :show="
                showOptionDropdown.status && showOptionDropdown.id === group._id
              "
            >
              <button
                @click="$emit('edit')"
                class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
              >
                {{ $t("edit") }}
              </button>
              <button
                @click.prevent="openConfirmModal(group._id)"
                class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
              >
                {{ $t("delete") }}
              </button>
            </option-dropdown-new>

            <div
              class="absolute -bottom-16 w-80"
              v-if="showConfirm.status && showConfirm.id === group._id"
            >
              <confirm-modal
                :show="showConfirm.status && showConfirm.id === group._id"
                @no="closeConfirmModal"
                @yes="confirmDelete(group._id)"
              ></confirm-modal>
            </div>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StatusModal from "../StatusModal.vue";
import ConfirmModal from "../ConfirmModal.vue";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";

export default {
  components: { StatusModal, ConfirmModal, OptionDropdownNew },
  computed: {
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.group?.admin || !!this.currentUser?.isSuper)
      );
    },
  },

  data() {
    return {
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },

  props: ["group", "isSub", "isSubSub"],
  emits: ["edit"],
  methods: {
    ...mapActions("groups", ["changeStatus", "deleteGroup"]),
    ...mapActions("subgroups", { changeSubStatus: "changeStatus" }),
    ...mapActions("subgroups", ["deleteSubGroup"]),
    ...mapActions("sub_subgroups", { changeSub_SubStatus: "changeStatus" }),
    ...mapActions("sub_subgroups", ["deleteSubSubGroup"]),
    getGroupName(users) {
      const names = users.map((user) => `${user.name}`).join(", ");
      return users.length > 0 ? names : " ";
    },

    openStatusModal(group) {
      this.showStatus.id = group._id;
      this.showStatus.status = true;
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    openOptionDropDown(id) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      if (this.isSub) {
        this.deleteSubGroup(id);
      } else if (this.isSubSub) {
        this.deleteSubSubGroup(id);
      } else {
        this.deleteGroup(id);
      }
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },

  mounted() {
    document.addEventListener("click", (e) => {
      this.closeStatusModal(e);
    });
  },

  beforeUnmount() {
    document.removeEventListener("click", (e) => {
      this.closeStatusModal(e);
    });
  },

  watch: {
    async status(status) {
      if (this.sub_sub) {
        await this.changeSub_SubStatus({ status, id: this.group._id });
      } else if (this.sub) {
        await this.changeSubStatus({ status, id: this.group._id });
      } else {
        await this.changeStatus({ status, id: this.group._id });
      }
    },
  },
};
</script>
