<template>
  <form @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('group-name')"
        type="text"
        v-model="name"
      />
    </div>
    <div class="mb-4" v-if="group">
      <select-input
        :placeholder="$t('status')"
        :data="statusData"
        v-model="status"
        size="full"
      />
    </div>
    <div class="flex relative items-center justify-end pt-2 gap-x-3">
      <confirm-modal
        :show="showConfirm"
        @no="closeConfirmModal"
        @yes="confirmed(group._id)"
      ></confirm-modal>

      <button
        :disabled="loading"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          this.group
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? $t("adding")
            : $t("add-group")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { mapMutations } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  components: { SelectInput, ConfirmModal },
  data() {
    return {
      loading: false,
      error: null,
      name: this.group ? this.group.name : "",
      status: this.group?.status,
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
    };
  },
  emits: ["onClose"],
  methods: {
    ...mapMutations("groups", ["addGroup", "updateGroup"]),
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.name) return (this.error = "Please fill in all fields");

      try {
        this.loading = true;
        if (this.group) {
          if (!this.status) {
            this.loading = false;
            this.error = "Please add status";
            return;
          }

          const { data } = await httpClient.put(
            `/admin/groups/${this.group._id}`,
            {
              name: this.name,
              status: this.status,
            }
          );

          this.updateGroup(data);
        } else {
          const { data } = await httpClient.post(`/admin/groups`, {
            name: this.name,
          });

          this.addGroup(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },
  },
  props: ["group"],
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
